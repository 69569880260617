input{
  width:100%;
  background-color: #FFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  height: 58px;
  font-size:20px;
  padding-left:20px;
  &::placeholder{
    color: #d9d9d9;
  }
  &:focus{
    outline: 3px solid lighten($purple, 10 );
  }
}
