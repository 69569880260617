.filters{
  display: flex;
  margin-top: 20px;
  gap: 10px;
  li{
    width:auto;
    padding:5px 20px;
    border-radius:4px;
    color:#FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}