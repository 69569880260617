.profile_button{
  width:170px;
  border-radius: 50px;
  height:55px;
  background-color: $purple;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#FFF;
  padding-left:20px;
  font-size:15px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  place-self: center end;
  transition: all 0.3s ease-in-out;
  img{
    background-color: #FFF;
    border-radius: 50%;
    padding:5px;
    min-width: 60px;
  }
  &:hover{
    background-color: lighten($purple, 5);
  }
}