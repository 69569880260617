.button{
  width:fit-content;
  background-color: $purple;
  height:60px;
  margin: 40px 0;
  place-self: center;
  display: flex;
  justify-content: space-between;
  font-size:15px;
  color:#FFF;
  padding:0 30px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  i{
    font-size:20px;
  }

  &:hover{
    cursor: pointer;
    background-color: lighten($purple, 3);
  }

  span{
    margin-left: 20px;
  }
}