.place{
  width:100%;
  background-color: #FFF;
  height:250px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 380px 1fr ;
  grid-template-rows: 80px 1fr 50px;
  column-gap: 20px;
  img{
    height:100%;
    grid-row: 1 /span 3;
    grid-column: 1;
  }


  div{
    display: flex;
    align-items: center;
    gap: 10px;
    height:fit-content;
    place-self: center start;
    h2{
      grid-row: 1;
      grid-column: 2;
      font-size:30px;
      font-weight: bold;
      place-self: center start;
      width:100%;
    }

    ul{
      grid-row: 1;
      grid-column: 2;
      display: flex;
      gap:5px;
      width:80%;
      li{
        width:auto;
        background-color: $blue;
        padding:3px 7px;
        border-radius: 5px;
        color:#FFF;
        font-size:10px;
        height:fit-content;
      }
    }
  }

  p{
    grid-row: 2;
    grid-column: 2;
  }
  div{
    display: flex;
    justify-content: space-between;
    width:100%;
    padding:0 20px 0 0;
    small{
      grid-row: 3;
      grid-column: 2;
    }
  }

  &:hover{
    background-color: darken(#FFF, 5);
    cursor: pointer;
  }
}