nav{
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-auto-rows: 150px;
  ul{
    display: flex;
    height:100%;
    align-items: center;
    justify-content: flex-end;
    grid-column: 1;
    width:100%;
    li{
      margin-left: 20px;
      a{
        color:#000;
        font-size:20px;
      }
    }
  }
  @import "profil";
}